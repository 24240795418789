import "../assets/scss/HomePage.scss";
import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Index = lazy(() => import("./Index"));
const Machine = lazy(() => import("./Machine"));

const HomePage = () => {
    return (
        <Routes>
            <Route path="/" element={<React.Suspense fallback={null}><Index /></React.Suspense>} />
            <Route path="/bot" element={<React.Suspense fallback={null}><Machine /></React.Suspense>} />
        </Routes>
    );
};

export default HomePage;